import { useNavigate } from 'react-router'
import { Button } from '@mui/material'
import { SHARE_APPOINTMENT_ROUTE } from 'utils/constants/Helpers'
import shareTreatmentCard from 'assets/images/shareTreatmentCard.png'
import './shareAppointmentCard.scss'
export const ShareAppointmentCard = () => {
  const navigate = useNavigate()

  const handleShareAppointment = () => {
    navigate(SHARE_APPOINTMENT_ROUTE)
  }

  return (
    <div className="share-appointment-card-container">
      <div className="content">
        <h4>Share The Glow</h4>
        <p>Book with a friend (or two!) for a group facial.</p>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleShareAppointment}
        >
          Share Appointment
        </Button>
      </div>
      <div className="image">
        <img
          src={shareTreatmentCard}
          alt="share-treatment"
          className="image"
          loading="eager"
        />
      </div>
    </div>
  )
}
