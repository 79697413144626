import React from 'react'
import { useNavigate } from 'react-router'
import { Button } from '@mui/material'

import './pageNotFound.scss'

const PageNotFound = () => {

    const navigate = useNavigate()

    return (
        <div className="page-not-found-container">
            <h1>404</h1>
            <h2>Page not found</h2>
            <Button onClick={() => navigate('/')} className="app-btn app-btn-outlined">Back to Home</Button>
        </div>
    )
}

export default PageNotFound