import React from 'react'
import Grid from '@mui/material/Grid2'
import {
  InputLabel,
  InputAdornment,
  FormControl,
  IconButton,
  Button,
  CircularProgress,
  FormHelperText,
  Typography,
  OutlinedInput,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ReactComponent as Eye } from 'assets/icons/eye.svg'
import { ReactComponent as EyeSlash } from 'assets/icons/eye-slash.svg'
import {
  getUserAuthSessionApi,
  getUserInfo,
  updatePhoneNumber,
  getUserAppointments,
} from 'services/User'
import { useLocation, useNavigate, Link } from 'react-router'
import { useSnackbar } from 'notistack'
import { AppContext } from 'providers/context/App'
import {
  conditionalClasses,
  numberOnly,
  setLocalstorageItem,
  textTrimmer,
} from 'utils/helper-functions'
import { LoginPayloadVariables } from 'utils/types/sessionTypes'
import {
  BOULEVARD_AUTH_API_TOKEN,
  CUSTOMER_ACCESS_TOKEN,
  CUSTOMER_SESSION_TOKEN,
  IS_BOULEVARD_CLIENT,
  FORGET_ROUTE,
  APPOINTMENT_CONFIRMATION_ROUTE,
  MEMBERSHIP_EXISTING_USER_MAIN_ROUTE,
} from 'utils/constants/Helpers'
import {
  LOGIN_MESSAGES,
  SIGNUP_MESSAGES,
  GENERAL_ERROR_MESSAGE,
} from 'utils/constants/Messages'
import { TrackPage } from 'services/Analytics'
import { getBoulevardAuthToken, loginTokenCreateApi } from 'services/Session'
import { Base64 } from 'js-base64'
import { ClientInfoResponse, MembershipVoucher } from 'utils/types/userTypes'
import validator, { loginSchema } from 'utils/yupSchema'
import { getMyMembershipVouchers } from 'utils/helper-functions/membershipInfo'

interface Props {
  email: string
  setEmailError: (data: string) => void
  isMembership?: boolean
}

interface State {
  email: string
  password: string
  showPassword: boolean
  phoneNumber: string
}

interface FieldValidate {
  email: string
  password: string
  phoneNumber: string
}

const AppointmentSignIn = ({ email, setEmailError, isMembership }: Props) => {
  const location = useLocation()
  const { appMasterData, updateData } = React.useContext(AppContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {
    TrackPage(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [loader, setLoader] = React.useState(false)
  const [isShow, setIsShow] = React.useState(false)
  const [values, setValues] = React.useState<State>({
    email: appMasterData.loginEmail || email || '',
    password: '',
    showPassword: false,
    phoneNumber: '',
  })
  const [signupstatus, setSignupStatus] = React.useState(true)
  const [errors, setErrors] = React.useState<FieldValidate>({
    email: '',
    password: '',
    phoneNumber: '',
  })

  let base64AccessToken = ''

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = { ...values, [prop]: event.target.value }
      if (prop === 'phoneNumber' && event.target.value) {
        const fieldvalid = { ...errors }
        fieldvalid.phoneNumber = numberOnly(textTrimmer(event.target.value))
          ? ''
          : SIGNUP_MESSAGES.PHONE_NUMBER_INVALID
        setErrors(fieldvalid)
      }
      setSignupStatus(!(newValues.email && newValues.password))
      setValues(newValues)
    }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const phoneNumberValidate = () => {
    const fieldValid = { ...errors }
    fieldValid.phoneNumber = ''

    if (!textTrimmer(values.phoneNumber))
      fieldValid.phoneNumber = SIGNUP_MESSAGES.PHONE_NUMBER_REQUIRED

    setErrors(fieldValid)
    return !fieldValid.phoneNumber
  }

  const userGetLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    // Validate fields
    const { errors: yupErrors, success } = await validator<FieldValidate>(
      loginSchema,
      {
        email,
        password: values.password,
        phoneNumber: '',
      }
    )
    setErrors(yupErrors)
    if (yupErrors.email) setEmailError(yupErrors.email)
    else setEmailError('')

    if (!success) return

    await userLogin({ email, password: values.password })
  }

  const userInfo = async () => {
    if (isMembership) {
      const response = await getUserInfo()
      if (response.is_member) {
        navigate(MEMBERSHIP_EXISTING_USER_MAIN_ROUTE)
      }
    }
  }

  const userLogin = async (payload: LoginPayloadVariables) => {
    setLoader(true)
    try {
      const res = await loginTokenCreateApi(payload)
      if (res?.customerAccessTokenCreate?.customerAccessToken?.accessToken) {
        const accessToken =
          res.customerAccessTokenCreate.customerAccessToken.accessToken
        setLocalstorageItem(CUSTOMER_ACCESS_TOKEN, accessToken)
        base64AccessToken = Base64.encode(
          res.customerAccessTokenCreate.customerAccessToken.accessToken
        )

        const sessionRes = await getUserAuthSessionApi(
          base64AccessToken,
          payload
        )
        let membershipVouchers: MembershipVoucher[] = []
        if (sessionRes?.token) {
          setLocalstorageItem(CUSTOMER_SESSION_TOKEN, sessionRes?.token)
          const authTokenRes = await getBoulevardAuthToken()
          if (authTokenRes?.is_boulevard_client) {
            setLocalstorageItem(BOULEVARD_AUTH_API_TOKEN, authTokenRes?.token)
            membershipVouchers = (await getMyMembershipVouchers()) ?? []
          }
          const userAppointment = await getUserAppointments()
          updateData({
            user: { token: sessionRes?.token },
            membershipVouchers,
            previousBookedAppointments: userAppointment,
          })
          setLocalstorageItem(
            IS_BOULEVARD_CLIENT,
            !!authTokenRes?.is_boulevard_client &&
              authTokenRes?.is_boulevard_client
              ? authTokenRes?.is_boulevard_client.toString()
              : 'false'
          )

          enqueueSnackbar(LOGIN_MESSAGES.SUCCESS, {
            variant: 'success',
          })
        } else {
          setIsShow(false)
          enqueueSnackbar(LOGIN_MESSAGES.ERROR, {
            variant: 'error',
          })
        }

        // Get user info if its membership flow
        await userInfo()
      } else {
        setIsShow(false)
        enqueueSnackbar(LOGIN_MESSAGES.ERROR, {
          variant: 'error',
        })
      }
    } catch (error) {
      enqueueSnackbar(GENERAL_ERROR_MESSAGE, { variant: 'error' })
    } finally {
      setLoader(false)
    }
  }
  const phoneNumberUpdate = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault()
      if (phoneNumberValidate()) {
        let payload = {
          first_name: appMasterData.userInfo.first_name,
          last_name: appMasterData.userInfo.last_name,
          phone: values.phoneNumber,
        }
        setIsShow(false)
        const res: ClientInfoResponse = await updatePhoneNumber(
          base64AccessToken,
          payload
        )
        if (res) {
          updateData({ userInfo: res })
        }
      }
    } catch (error) {
      enqueueSnackbar(GENERAL_ERROR_MESSAGE, { variant: 'error' })
    }
  }

  React.useEffect(() => {
    if (appMasterData?.isLoggedIn && !appMasterData?.userInfo?.phone_number) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }, [appMasterData])

  return (
    <div
      className={conditionalClasses(
        Boolean(isMembership),
        'membership-login-signup-box',
        '',
        'login-signup-box'
      )}
    >
      {!isShow && (
        <form
          onSubmit={(e) => {
            userGetLogin(e).catch((e) => console.error(e))
          }}
        >
          <Grid className="input-filed-wrapper">
            <FormControl fullWidth variant="filled">
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                className="txt-field"
                id="standard-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                error={Boolean(errors.password)}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!values.showPassword ? <EyeSlash /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error={Boolean(errors.password)}>
                {errors.password || ''}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid className="input-filed-wrapper-link">
            <Link
              className="link-txt"
              to={`${FORGET_ROUTE}/from${APPOINTMENT_CONFIRMATION_ROUTE}`}
            >
              Forgot password?
            </Link>
          </Grid>
          <Grid>
            <LoadingButton
              variant="contained"
              className={signupstatus ? 'signup-btn' : 'app-btn'}
              fullWidth
              type="submit"
              onClick={(e) => {
                userGetLogin(e).catch((e) => console.error(e))
              }}
              disabled={signupstatus}
              loading={loader}
              loadingIndicator={
                <CircularProgress color={'primary'} size={20} />
              }
            >
              Sign in
            </LoadingButton>
          </Grid>
        </form>
      )}

      {isShow && (
        <form
          onSubmit={(e) => {
            phoneNumberUpdate(e).catch((e) => console.error(e))
          }}
        >
          <Grid>
            <Typography className="number-txt-head">
              You’re almost there!
            </Typography>
            <Typography className="number-txt-body">
              Looks like we’re missing your phone number. Add it to complete
              your account.
            </Typography>
          </Grid>
          <Grid className="input-filed-wrapper">
            <FormControl fullWidth variant="filled">
              <InputLabel htmlFor="standard-adornment-password">
                Phone Number
              </InputLabel>
              <OutlinedInput
                className="txt-field"
                id="standard-adornment-password"
                type="text"
                value={values.phoneNumber}
                error={Boolean(errors.phoneNumber)}
                inputProps={{
                  maxLength: 15,
                }}
                onChange={handleChange('phoneNumber')}
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      className="txt-link"
                      onClick={(e) => {
                        phoneNumberUpdate(e).catch((e) => console.error(e))
                      }}
                      disabled={!values.phoneNumber}
                    >
                      Submit
                    </Button>
                  </InputAdornment>
                }
              />
              <FormHelperText error={Boolean(errors.phoneNumber)}>
                {errors.phoneNumber ||
                  'For appointment scheduling and confirmation. '}
              </FormHelperText>
            </FormControl>
          </Grid>
        </form>
      )}
    </div>
  )
}

export default AppointmentSignIn
