import React from 'react'
import Grid from '@mui/material/Grid2'
import {
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  OutlinedInput,
  Chip,
} from '@mui/material'
import { emailVerification } from 'services/User'
import { useLocation } from 'react-router'
import { useSnackbar } from 'notistack'
import { AppContext } from 'providers/context/App'
import { conditionalClasses, emailVerifier } from 'utils/helper-functions'
import {
  GENERAL_ERROR_MESSAGE,
  SIGNUP_MESSAGES,
} from 'utils/constants/Messages'
import { TrackPage } from 'services/Analytics'
import { ReactComponent as StyledChip } from 'assets/icons/chip-with-tick.svg'
import { ReactComponent as GreenTick } from 'assets/icons/green-tick.svg'
import SignUp from './appointment-signup'
import SignIn from './appointment-signin'

type Props = {
  isMembership?: boolean
}

const SignInSignUp = ({ isMembership }: Props) => {
  const location = useLocation()
  const { appMasterData, updateData } = React.useContext(AppContext)

  React.useEffect(() => {
    TrackPage(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setIsLoggedIn(appMasterData?.isLoggedIn)
  }, [appMasterData])

  const { enqueueSnackbar } = useSnackbar()
  const [isNotExist, setIsNotExist] = React.useState(false)
  const [isExist, setIsExist] = React.useState(false)
  const [isShow, setIsShow] = React.useState(false)
  const [isExpand, setIsExpand] = React.useState(false)
  const [isBtnShow, setIsBtnShow] = React.useState(true)
  const [isLoggedIn, setIsLoggedIn] = React.useState(appMasterData.isLoggedIn)
  const [emailError, setEmailError] = React.useState('')
  const [email, setEmail] = React.useState('')

  const expandHandler = () => {
    setIsExpand(!isExpand)
  }

  const verifyEmail = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault()
      if (validation()) {
        const res = await emailVerification(email)
        if (res?.exists?.shopify) {
          updateData({ email: email })
          setEmailError('')
          setIsNotExist(false)
          if (res?.customer?.shopify_status === 'enabled') {
            setIsNotExist(false)
            setIsExist(true)
          } else if (res?.customer?.shopify_status === 'invited') {
            setIsNotExist(false)
            setIsExist(false)
            enqueueSnackbar(SIGNUP_MESSAGES.SHOPIFY_STATUS_SIGNUP, {
              variant: 'warning',
            })
          }
        } else {
          setEmailError('')
          updateData({ email: '' })
          setIsNotExist(true)
          setIsExist(false)
        }
        setIsBtnShow(false)
      }
    } catch (error) {
      enqueueSnackbar(GENERAL_ERROR_MESSAGE, { variant: 'error' })
    }
  }

  const validation = () => {
    setEmailError(emailVerifier(email) ? '' : SIGNUP_MESSAGES.INVALID_EMAIL)

    return emailVerifier(email)
  }
  const onClose = () => {
    if (appMasterData.isLoggedIn) {
      setIsLoggedIn(true)
      if (appMasterData?.userInfo?.phone_number) {
        setIsShow(false)
        setIsLoggedIn(true)
        setIsExist(false)
        setIsNotExist(false)
      } else {
        setIsExist(true)
        setIsShow(true)
        setIsLoggedIn(false)
      }
    } else {
      setIsLoggedIn(false)
    }
  }

  React.useEffect(() => {
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appMasterData])

  React.useEffect(() => {
    if (appMasterData.loginEmail) {
      setEmail(appMasterData.loginEmail)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={conditionalClasses(
        Boolean(isMembership),
        'membership-login-signup-box',
        '',
        'login-signup-box'
      )}
    >
      <Accordion expanded={!isLoggedIn}>
        <AccordionSummary
          expandIcon={isLoggedIn && !isMembership ? <GreenTick /> : null}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={expandHandler}
          className="accordion-summary"
        >
          <div className="payment-head">
            {isLoggedIn && isMembership ? <StyledChip /> : <Chip label="1" />}
            Sign In / Sign Up
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            className="signup-container"
            size={{
              xs: 12,
            }}
          >
            {!isShow && (
              <Grid className="input-filed-wrapper">
                <FormControl fullWidth variant="filled">
                  <form
                    onSubmit={(e) => {
                      verifyEmail(e).catch((e) => console.error(e))
                    }}
                  >
                    <InputLabel htmlFor="standard-adornment-password">
                      Email Address
                    </InputLabel>
                    <OutlinedInput
                      className="txt-field"
                      id="standard-adornment-password"
                      type="text"
                      value={email}
                      fullWidth
                      error={Boolean(emailError)}
                      onChange={(event) =>
                        setEmail(event?.target.value.toLowerCase())
                      }
                      endAdornment={
                        <InputAdornment position="end" className="txt-link">
                          {isBtnShow && (
                            <Button
                              className="txt-link"
                              onClick={(e) => {
                                verifyEmail(e).catch((e) => console.error(e))
                              }}
                              disabled={!email}
                            >
                              Next
                            </Button>
                          )}
                        </InputAdornment>
                      }
                    />
                    {emailError && (
                      <FormHelperText error={Boolean(emailError)}>
                        {emailError || ''}
                      </FormHelperText>
                    )}
                  </form>
                </FormControl>
              </Grid>
            )}
          </Grid>
          {isNotExist && (
            <SignUp
              email={email}
              setEmailError={(emailError) => setEmailError(emailError)}
              isMembership={Boolean(isMembership)}
            />
          )}
          {(isExist || appMasterData.loginEmail) && (
            <SignIn
              email={email}
              setEmailError={(emailError) => setEmailError(emailError)}
              isMembership={Boolean(isMembership)}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default SignInSignUp
