import { Navigate } from 'react-router'
import React from 'react'
// interface
import IRouteItem from '../interface/router'
import {
  APPOINTMENT_CONFIRMATION_ROUTE,
  CONFIRM_APPOINTMENT_ROUTE,
  ENHANCEMENT_ROUTE,
  ESTHETICIAN_LIST_ROUTE,
  FORGET_ROUTE,
  FORGET_SUCCESS_ROUTE,
  HEALTH_CHECK_ROUTE,
  LOCATIONS_ROUTE,
  LOGIN_ROUTE,
  LOGIN_ROUTE_CUSTOM,
  SIGNUP_ROUTE,
  SIGNUP_ROUTE_CUSTOM,
  WELCOME_ROUTE,
  FORGET_SUCCESS_ROUTE_CUSTOM,
  FORGET_ROUTE_CUSTOM,
  GROUP_BOOKING,
  SHARE_APPOINTMENT_ROUTE,
  LASER_MAIN_ROUTE,
  FACIALS_ROUTE,
  MEMBERSHIP_MAIN_ROUTE
} from '../utils/constants/Helpers'
import { MembershipRouter } from './memershipRouter'
import { LaserRouter } from './laserRoute'
import Facial from 'views/pages/facial'
// components
const ConfirmedAppointment = React.lazy(
  () => import('views/pages/confirmedAppointment')
)

const AppointmentConfirmation = React.lazy(
  () => import('../views/pages/appointmentConfirmation')
)
const Enhancement = React.lazy(() => import('../views/pages/enhancement'))
const EstheticianList = React.lazy(
  () => import('../views/pages/estheticianList')
)
const LocationSelector = React.lazy(
  () => import('../views/pages/locationSelection')
)
const Login = React.lazy(() => import('../views/pages/login'))
const HealthCheck = React.lazy(() => import('../views/pages/health-check'))
const SignUp = React.lazy(() => import('../views/pages/signup'))
const Welcome = React.lazy(() => import('../views/pages/welcome'))
const Forget = React.lazy(() => import('../views/pages/forget'))
const ForgetSuccess = React.lazy(
  () => import('../views/pages/forget/forgetSuccess')
)
const GroupBooking = React.lazy(() => import('../views/pages/group-booking'))
const ShareAppointment = React.lazy(
  () => import('views/pages/shareAppointment')
)

// define app routes
export const routes: Array<IRouteItem> = [
  {
    key: 'root',
    title: 'Location',
    path: '/',
    component: <Navigate to="locations" />,
  },
  {
    key: 'locations',
    title: 'Locations',
    path: LOCATIONS_ROUTE,
    component: <LocationSelector />,
  },
  {
    key: 'facials',
    title: 'Facials',
    path: FACIALS_ROUTE,
    component: <Facial />,
  },
  {
    key: 'esthetician-list',
    title: 'Esthetician List',
    path: ESTHETICIAN_LIST_ROUTE,
    component: <EstheticianList />,
  },
  {
    key: 'router-Welcome',
    title: 'Welcome',
    tooltip: 'Welcome',
    path: WELCOME_ROUTE,
    component: <Welcome />,
  },
  {
    key: 'sign-up',
    title: 'SignUp',
    path: SIGNUP_ROUTE,
    component: <SignUp />,
  },
  {
    key: 'login',
    title: 'Login',
    tooltip: 'Login',
    path: LOGIN_ROUTE,
    component: <Login />,
  },
  {
    key: 'login-custom',
    title: 'Login-custom',
    tooltip: 'Login-custom',
    path: LOGIN_ROUTE_CUSTOM,
    component: <Login />,
  },
  {
    key: 'signup-custom',
    title: 'signup-custom',
    tooltip: 'signup-custom',
    path: SIGNUP_ROUTE_CUSTOM,
    component: <SignUp />,
  },
  {
    key: 'enhancement',
    title: 'Enhancement',
    path: ENHANCEMENT_ROUTE,
    component: <Enhancement />,
  },
  {
    key: 'health-check',
    title: 'Health Check',
    path: HEALTH_CHECK_ROUTE,
    component: <HealthCheck />,
  },
  {
    key: 'appointment-confirmation',
    title: 'Appointment Confirmation',
    path: APPOINTMENT_CONFIRMATION_ROUTE,
    component: <AppointmentConfirmation />,
  },
  {
    key: 'confirm-appointment',
    title: 'Confirm Appointment',
    path: CONFIRM_APPOINTMENT_ROUTE,
    component: <ConfirmedAppointment />,
  },
  {
    key: 'forget',
    title: 'Forget',
    tooltip: 'Forget',
    path: FORGET_ROUTE,
    component: <Forget />,
  },
  {
    key: 'forget-custom',
    title: 'Forget',
    tooltip: 'Forget-custom',
    path: FORGET_ROUTE_CUSTOM,
    component: <Forget />,
  },
  {
    key: 'forget-success',
    title: 'Forget Success',
    tooltip: 'Forget Sucesss',
    path: FORGET_SUCCESS_ROUTE,
    component: <ForgetSuccess />,
  },
  {
    key: 'forget-success-custom',
    title: 'Forget Success',
    tooltip: 'Forget Success custom',
    path: FORGET_SUCCESS_ROUTE_CUSTOM,
    component: <ForgetSuccess />,
  },
  {
    key: 'membership',
    title: 'Membership',
    tooltip: 'Membership',
    path: MEMBERSHIP_MAIN_ROUTE,
    component: <MembershipRouter />,
  },
  {
    key: 'laser',
    title: 'laser',
    tooltip: 'laser',
    path: LASER_MAIN_ROUTE,
    component: <LaserRouter />,
  },
  {
    key: 'groupBooking',
    title: 'GroupBooking',
    tooltip: 'GroupBooking',
    path: GROUP_BOOKING,
    component: <GroupBooking />,
  },
  {
    key: 'share-appointment',
    title: 'Share Appointment',
    path: SHARE_APPOINTMENT_ROUTE,
    component: <ShareAppointment />,
  },
]
