import { Suspense } from 'react'
import { Route, Routes } from 'react-router'
import ScrollToTop from '../views/components/scroll-to-top'
import { routes } from './routes'
import Lottie from 'lottie-react'
import loadingData from '../utils/lottie/loading_spinner_primaryAccent.json'
import { Box } from '@mui/material'
import PageNotFound from 'views/components/page-not-found'

const Router = () => {
  return (
    <ScrollToTop>
      <Suspense
        fallback={
          <Box
            className="app-loader"
            style={{ top: 0 }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Lottie
              className={'loading-lottie'}
              autoPlay={false}
              loop
              animationData={loadingData}
            />
          </Box>
        }
      >
        <Routes>
          {routes.map((route) => {
            return (
              <Route
                path={route.path}
                element={route.component}
                key={route.key}
                caseSensitive={true}
              />
            )
          })}
          <Route key="404" path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ScrollToTop>
  )
}

export default Router
