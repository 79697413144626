import { useContext, useEffect, useState } from 'react'
import { chain, find } from 'lodash'
import { useSnackbar } from 'notistack'
import { AppContext } from 'providers/context/App'
import { useLoading } from 'providers/context/Loading'
import { anyEsth } from 'providers/context/EstheticianList'
import {
  LASER_ESTHETICIAN_LIST_ROUTE,
  LASER_FACIAL_LOCATION,
  LOCATIONS_ROUTE,
} from 'utils/constants/Helpers'
import { AvailableItems } from 'utils/types/cartTypes'
import { LocationPlace, LocationsRes } from 'utils/types/locationTypes'
import { createCart, getCart } from 'services/Cart'
import { getLocations } from 'services/Location'
import { Navigate, useNavigate } from 'react-router'
import { GENERAL_ERROR_MESSAGE } from 'utils/constants/Messages'
import { AppData } from 'utils/types/appTypes'

const generateLocationData = (
  appMasterData: AppData,
  selectedLocation: LocationPlace
) => ({
  name: selectedLocation?.name ?? '',
  locationId: selectedLocation?.id ?? '',
  tz: selectedLocation?.timezone ?? '',
  mapImg: selectedLocation?.mapImg ?? '',
  advanceBookingLimit: selectedLocation?.advanceBookingLimit ?? 2,
  enableGroupBooking: selectedLocation?.enableGroupBooking ?? false,
  enableGhostBooking: selectedLocation?.enableGhostBooking ?? false,
  enableMembershipFlow: selectedLocation?.enableMembershipFlow ?? false,
  address: selectedLocation?.address ?? appMasterData.location.address,
  contact: selectedLocation?.contact ?? appMasterData.location.contact,
  marketingSupport:
    selectedLocation?.marketingSupport ??
    appMasterData.location.marketingSupport,
})

const LaserFacialLanding = () => {
  const { appMasterData, updateData } = useContext(AppContext)
  const navigate = useNavigate()
  const { setLoading } = useLoading()
  const { enqueueSnackbar } = useSnackbar()
  const [cartReady, setCartReady] = useState(false)

  const createLaserFacial = async () => {
    try {
      // Handling two scenarios: one with straight '/leser' and one coming from the '/locations' screen.
      let locationObj
      if (appMasterData?.location?.locationId) {
        locationObj = appMasterData.location
      } else {
        const locationData: LocationsRes[] = await getLocations()

        const selectedState = find(locationData, {
          places: [{ id: LASER_FACIAL_LOCATION }],
        })?.places

        const selectedLocation = find(selectedState, {
          id: LASER_FACIAL_LOCATION,
        })!
        locationObj = generateLocationData(appMasterData, selectedLocation)
      }

      const laserFacialLocation =
        appMasterData?.location?.locationId || LASER_FACIAL_LOCATION
      const createdCart = await createCart(laserFacialLocation)
      const getLaserFacialObj: AvailableItems = chain(
        createdCart.createCart.cart.availableCategories
      )
        .filter({ name: 'Laser' })
        .take()
        .first()
        .thru((x) => find(x.availableItems, { name: 'Laser Facial' }))
        .value()
      // No laser facial data redirect to locations screen
      if (!getLaserFacialObj) {
        return navigate(LOCATIONS_ROUTE)
      }
      const getCartValues = await getCart(
        createdCart.createCart.cart.id,
        getLaserFacialObj.id
      )

      const staffVariants = getCartValues.cart.availableItem.staffVariants

      staffVariants.unshift(anyEsth)

      updateData({
        cart: {
          ...appMasterData.cart,
          cartId: createdCart.createCart.cart.id ?? '',
        },
        location: locationObj,
        category: {
          categoryId: getLaserFacialObj.id,
          categoryObj: getLaserFacialObj,
        },
        availableEstheticians: staffVariants,
        selectedEnhancements: [],
        isLaserFacial: true,
      })
    } catch (error) {
      console.error(error)
      enqueueSnackbar(GENERAL_ERROR_MESSAGE, {
        variant: 'error',
      })
    } finally {
      setCartReady(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    createLaserFacial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return cartReady ? <Navigate to={LASER_ESTHETICIAN_LIST_ROUTE} /> : <></>
}

export default LaserFacialLanding
