import React from 'react'
import Grid from '@mui/material/Grid2'
import {
  TextField,
  InputLabel,
  InputAdornment,
  FormControl,
  IconButton,
  OutlinedInput,
  CircularProgress,
  FormHelperText,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ReactComponent as Eye } from 'assets/icons/eye.svg'
import { ReactComponent as EyeSlash } from 'assets/icons/eye-slash.svg'
import {
  getUserAuthSessionApi,
  signUpCreateUserApi,
  updatePhoneNumber,
} from 'services/User'
import { useSnackbar } from 'notistack'
import { AppContext } from 'providers/context/App'
import {
  numberOnly,
  setLocalstorageItem,
  textTrimmer,
} from 'utils/helper-functions'
import {
  LoginSignupFieldValidate,
  LoginPayloadVariables,
  LoginSignupState,
} from 'utils/types/sessionTypes'
import {
  BOULEVARD_AUTH_API_TOKEN,
  CUSTOMER_ACCESS_TOKEN,
  CUSTOMER_SESSION_TOKEN,
  IS_BOULEVARD_CLIENT,
} from 'utils/constants/Helpers'
import { LOGIN_MESSAGES, SIGNUP_MESSAGES } from 'utils/constants/Messages'
import { TrackPage } from 'services/Analytics'
import { getBoulevardAuthToken, loginTokenCreateApi } from 'services/Session'
import { Base64 } from 'js-base64'
import { useLocation } from 'react-router'
import { ClientInfoResponse } from 'utils/types/userTypes'
import validator, { signupSchema } from 'utils/yupSchema'

interface Props {
  email: string
  setEmailError: (data: string) => void
  isMembership?: boolean
}

const AppointmentSignUp = ({ email, setEmailError, isMembership }: Props) => {
  const location = useLocation()
  const { appMasterData, updateData } = React.useContext(AppContext)

  React.useEffect(() => {
    TrackPage(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { enqueueSnackbar } = useSnackbar()
  const [loader, setLoader] = React.useState(false)
  const [values, setValues] = React.useState<LoginSignupState>({
    password: '',
    emailId: email,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    showPassword: false,
  })
  const [signupstatus, setSignupStatus] = React.useState(true)
  const [errors, setErrors] = React.useState<LoginSignupFieldValidate>({
    emailId: '',
    firstName: '',
    lastName: '',
    password: '',
    phoneNumber: '',
  })

  let base64AccessToken = ''
  const handleChange =
    (prop: keyof LoginSignupState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (prop === 'phoneNumber' && event.target.value) {
        const fieldvalid = { ...errors }
        fieldvalid.phoneNumber = ''
        if (!numberOnly(textTrimmer(event.target.value))) {
          fieldvalid.phoneNumber = SIGNUP_MESSAGES.PHONE_NUMBER_INVALID
        }
        setErrors(fieldvalid)
      }

      const newValues = { ...values, [prop]: event.target.value }

      if (
        email &&
        newValues.firstName &&
        newValues.lastName &&
        newValues.password &&
        newValues.phoneNumber
      ) {
        setSignupStatus(false)
      }

      setValues(newValues)
    }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const phoneNumberUpdate = async () => {
    let payload = {
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phoneNumber,
    }
    const res: ClientInfoResponse = await updatePhoneNumber(
      base64AccessToken,
      payload
    )
    if (res) {
      updateData({ userInfo: res })
    }
  }

  const registerUser = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault()
      const { success, errors: yupErrors } =
        await validator<LoginSignupFieldValidate>(signupSchema, {
          ...values,
          emailId: email || '',
        })
      setErrors(yupErrors)
      if (yupErrors.emailId) setEmailError(yupErrors.emailId)
      else setEmailError('')
      setLoader(true)
      if (success) {
        const {
          password = '',
          emailId = '',
          firstName = '',
          lastName = '',
        } = values
        const variables = {
          password,
          email: emailId,
          firstName,
          lastName,
        }
        setLoader(true)
        const res = await signUpCreateUserApi(variables)
        if (res?.customerCreate?.customer?.id) {
          const userObj = await userLogin({ email: emailId, password }, true)
          updateData(userObj)
          await phoneNumberUpdate()
        } else {
          throw Error(SIGNUP_MESSAGES.SIGNUP_FAILED)
        }
      }
    } catch (error) {
      enqueueSnackbar(SIGNUP_MESSAGES.SIGNUP_FAILED, {
        variant: 'error',
      })
    } finally {
      setLoader(false)
    }
  }

  const userLogin = async (
    payload: LoginPayloadVariables,
    preventUpdate: boolean = false
  ) => {
    let result = { user: { token: '' } }
    const res = await loginTokenCreateApi(payload)
    if (res?.customerAccessTokenCreate?.customerAccessToken?.accessToken) {
      const accessToken =
        res.customerAccessTokenCreate.customerAccessToken.accessToken
      setLocalstorageItem(CUSTOMER_ACCESS_TOKEN, accessToken)
      base64AccessToken = Base64.encode(
        res.customerAccessTokenCreate.customerAccessToken.accessToken
      )

      const sessionRes = await getUserAuthSessionApi(base64AccessToken, payload)
      if (sessionRes?.token) {
        setLocalstorageItem(CUSTOMER_SESSION_TOKEN, sessionRes?.token)
        result = { user: { token: sessionRes?.token } }
        if (!preventUpdate) {
          updateData(result)
        }
        const authTokenRes = await getBoulevardAuthToken()
        if (authTokenRes?.is_boulevard_client) {
          setLocalstorageItem(BOULEVARD_AUTH_API_TOKEN, authTokenRes?.token)
        }
        setLocalstorageItem(
          IS_BOULEVARD_CLIENT,
          authTokenRes?.is_boulevard_client
            ? authTokenRes?.is_boulevard_client.toString()
            : 'false'
        )

        enqueueSnackbar(SIGNUP_MESSAGES.SIGNUP_SUCCESS, {
          variant: 'success',
        })
      } else {
        enqueueSnackbar(LOGIN_MESSAGES.ERROR, {
          variant: 'error',
        })
      }
    } else {
      enqueueSnackbar(LOGIN_MESSAGES.ERROR, {
        variant: 'error',
      })
    }
    return result
  }

  return (
    <form
      onSubmit={(e) => {
        registerUser(e).catch((e) => console.error(e))
      }}
    >
      {!appMasterData.isLoggedIn && (
        <>
          <Grid className="input-filed-wrapper">
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 6,
                }}
              >
                <TextField
                  className="txt-field"
                  label="First Name"
                  variant="filled"
                  fullWidth
                  onChange={handleChange('firstName')}
                  value={values.firstName}
                  helperText={errors.firstName || ''}
                  error={Boolean(errors.firstName)}
                />
              </Grid>
              <Grid
                size={{
                  xs: 6,
                }}
              >
                <TextField
                  className="txt-field"
                  label="Last Name"
                  variant="filled"
                  fullWidth
                  onChange={handleChange('lastName')}
                  value={values.lastName}
                  helperText={errors.lastName || ''}
                  error={Boolean(errors.lastName)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className="input-filed-wrapper">
            <FormControl fullWidth variant="filled">
              <InputLabel htmlFor="standard-adornment-password">
                Phone Number
              </InputLabel>
              <OutlinedInput
                className="txt-field"
                id="standard-adornment-password"
                type="text"
                value={values.phoneNumber}
                error={Boolean(errors.phoneNumber)}
                onChange={handleChange('phoneNumber')}
                inputProps={{
                  maxLength: 15,
                }}
              />
              <FormHelperText error={Boolean(errors.phoneNumber)}>
                {errors.phoneNumber ||
                  'For appointment scheduling and confirmation. '}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid className="input-filed-wrapper">
            <FormControl fullWidth variant="filled" className="text-box">
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                className="txt-field"
                id="standard-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                error={Boolean(errors.password)}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!values.showPassword ? <EyeSlash /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error={Boolean(errors.password)}>
                {errors.password || 'Must be 5 characters long'}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid className="input-filed-wrapper-btn">
            <LoadingButton
              variant="contained"
              className={signupstatus ? 'signup-btn' : 'app-btn'}
              fullWidth
              type="submit"
              onClick={(e) => {
                registerUser(e).catch((e) => console.error(e))
              }}
              disabled={signupstatus}
              loading={loader}
              loadingIndicator={
                <CircularProgress color={'primary'} size={20} />
              }
            >
              Sign Up
            </LoadingButton>
          </Grid>
        </>
      )}
    </form>
  )
}

export default AppointmentSignUp
