import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../../components/common-header'
import './appointmentConfirmation.scss'
import { HeaderTheme } from '../../../utils/types/appTypes'
import { useLocation, useNavigate } from 'react-router'
import AppointmentHeader from '../../components/appointment/appointment-header'
import AppointmentPrice from '../../components/appointment/appointment-price'
import AppointmentPayment from '../../components/appointment/appointment-payment'
import AppointmentNote from '../../components/appointment/appointment-note'
import AppointmentMap from '../../components/appointment/appointment-map'
import AppointmentFooter from '../../components/appointment/appointment-footer'
import AppointmentBanner from '../../components/appointment/appointment-banner'
import AppointmentConfirmBtn from '../../components/appointment/appointment-confirm-btn'
import ShowNewUserMessage from '../../components/appointment/show-new-user-message'
import { AppContext } from '../../../providers/context/App'
import AppointmentConfirmationProvider from '../../../providers/context/AppointmentConfirmation'
import {
  InitiateCheckoutAnalytics,
  TrackPage,
} from '../../../services/Analytics'
import SignInSignUp from '../../components/appointment/signIn-signup'
import {
  ENABLE_MEMBERSHIP_UPSELL,
  ESTHETICIAN_LIST_ROUTE,
  HEALTH_CHECK_ROUTE,
  LASER_ESTHETICIAN_LIST_ROUTE,
  LASER_HEALTH_CHECK_ROUTE,
  LOCATIONS_ROUTE,
  RETURNING_NON_MEMBER_BANNER_CITIES
} from '../../../utils/constants/Helpers'
import { findDateDifference } from '../../../utils/helper-functions'
import AppointmentEnhancementList from 'views/components/appointment/appointment-enhancement-list'
import MembershipUpsell from 'views/components/appointment/appointment-membership-upsell'
import ReturningNonMemberBanner from 'views/components/appointment/returning-non-member-banner'

const AppointmentConfirmation = () => {
  const { appMasterData, updateData } = useContext(AppContext)
  const [dateDiff, setDateDiff] = useState(0)
  const [actualTotal, setActualTotal] = useState(
    appMasterData.category.categoryObj?.listPrice as number
  )
  const navigate = useNavigate()
  const location = useLocation()
  const isLaserFacial = appMasterData.isLaserFacial

  useEffect(() => {
    if (!appMasterData.location.locationId) {
      navigate(LOCATIONS_ROUTE)
    }
    TrackPage(location.pathname)
    InitiateCheckoutAnalytics(appMasterData)
    setDateDiff(findDateDifference(appMasterData.selectedDate, new Date()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const backToFunction = () => {
    updateData({
      cart: {
        ...appMasterData.cart,
        isPromoCodeApplied: false,
        promoCodes: [],
      },
    })
    navigate(isLaserFacial ? LASER_HEALTH_CHECK_ROUTE : HEALTH_CHECK_ROUTE)
  }
  const backToEsthetician = () => {
    updateData({
      cart: {
        ...appMasterData.cart,
        isPromoCodeApplied: false,
        promoCodes: [],
      },
    })
    navigate(
      isLaserFacial ? LASER_ESTHETICIAN_LIST_ROUTE : ESTHETICIAN_LIST_ROUTE
    )
  }
  // Condition is true if the user has a past appointment, is a non-member, and is in one of the RETURNING_NON_MEMBER_BANNER_CITIES.
  const returningNonMemberCondition = appMasterData.previousBookedAppointments.length > 0 && !appMasterData.userInfo.is_member && RETURNING_NON_MEMBER_BANNER_CITIES.includes(appMasterData.location.name)

  const isLaserFacialSelected = appMasterData.selectedFacial.toLowerCase() === 'laser facial'

  return (
    <AppointmentConfirmationProvider>
      <CommonHeader
        backToFunction={() =>
          dateDiff < 6 ? backToFunction() : backToEsthetician()
        }
        theme={HeaderTheme.LIGHT}
      />
      <div className="appointment-container">
        <AppointmentHeader />

        <AppointmentBanner />

        {appMasterData.isLoggedIn && appMasterData.previousBookedAppointments.length < 1 && isLaserFacialSelected &&
          <ShowNewUserMessage />
        }

        <AppointmentEnhancementList />

        {!!appMasterData.selectedEnhancements.length && (
          <hr className="divider" />
        )}

        <AppointmentPrice setTotal={setActualTotal} />

        {returningNonMemberCondition && (
          <ReturningNonMemberBanner />
        )}

        {ENABLE_MEMBERSHIP_UPSELL &&
          appMasterData.previousBookedAppointments.length > 0 && !isLaserFacial && (
            <MembershipUpsell actualTotal={actualTotal} />
          )}

        <hr className="divider" />
        <SignInSignUp />
        <hr className="divider" />
        <AppointmentPayment />

        <hr className="custom-divider" />

        <AppointmentNote />

        <hr className="divider" />

        <AppointmentMap />

        <hr className="divider" />

        <AppointmentConfirmBtn />

        <AppointmentFooter />
      </div>
    </AppointmentConfirmationProvider>
  )
}

export default AppointmentConfirmation
