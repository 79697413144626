import React, { useEffect } from 'react'
import './scss/style.scss'
import Router from './router'
import AppProvider from './providers/context/App'
import { Axios } from './services/Axios'
import {
  generatePublicClientToken,
  setLocalstorageItem,
} from './utils/helper-functions'
import { LoadingProvider } from './providers/context/Loading'
import Content from './views/layout/content'
import { BOULEVARD_PUBLIC_API_TOKEN } from './utils/constants/Helpers'
import ToastContainer from './views/components/toast-container'
import Loader from './views/components/loader'
import { Helmet } from 'react-helmet'
import { BrowserRouter } from 'react-router'
import ErrorProvider from './providers/context/Error'
import ReactGA from 'react-ga'
import { ViewContentAnalytics } from './services/Analytics'
import ReactPixel from 'react-facebook-pixel'
import { HEYDAY_SKIN_CARE } from './utils/constants/StaticLinks'

ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`, {
  debug: false, // set true to check execution of google analytics commands in console.
  titleCase: false,
})

ReactPixel.init(`${process.env.REACT_APP_FB_PIXEL_ID}`, undefined, {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
})
ReactPixel.pageView()

window.addEventListener('message', function (e) { // NOSONAR
  const allowedOrigins = [HEYDAY_SKIN_CARE, 'localhost']
  if (!allowedOrigins.some((origin) => e.origin.includes(origin))) return

  const message = e.data
  if (message === 'widget open') {
    ViewContentAnalytics()
  }
})

function App() {
  /**
   *  Trigger when not rendered in iframe. For mobile.
   * */
  useEffect(() => {
    if (window.location === window.parent.location) {
      setTimeout(() => {
        ViewContentAnalytics()
      }, 5000)
    }
  }, [])

  const publicApiToken = generatePublicClientToken(
    process.env.REACT_APP_BOULEVARD_APIKEY ?? ''
  )
  Axios.setAuthToken(publicApiToken)

  setLocalstorageItem(BOULEVARD_PUBLIC_API_TOKEN, publicApiToken)

  return (
    <>
      <Helmet />
      <BrowserRouter>
        <ErrorProvider>
          <ToastContainer>
            <LoadingProvider>
              <AppProvider>
                <Loader />
                <Content>
                  <Router />
                </Content>
              </AppProvider>
            </LoadingProvider>
          </ToastContainer>
        </ErrorProvider>
      </BrowserRouter>
    </>
  )
}

export default App
