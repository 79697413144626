import IRouteItem from 'interface/router'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router'
import {
  APPOINTMENT_CONFIRMATION_ROUTE,
  CONFIRM_APPOINTMENT_ROUTE,
  ESTHETICIAN_LIST_ROUTE,
  HEALTH_CHECK_ROUTE,
} from 'utils/constants/Helpers'
import LaserFacialLanding from 'views/pages/laserFacialLanding'
import AppointmentConfirmation from 'views/pages/appointmentConfirmation'
import ConfirmedAppointment from 'views/pages/confirmedAppointment'
import EstheticianList from 'views/pages/estheticianList'
import HealthCheck from 'views/pages/health-check'
import PageNotFound from 'views/components/page-not-found'

const laserRoutes: Array<IRouteItem> = [
  {
    key: 'laser-root',
    title: 'Laser Facial',
    path: '/',
    component: <LaserFacialLanding />,
  },
  {
    key: 'esthetician-list',
    title: 'Esthetician List',
    path: ESTHETICIAN_LIST_ROUTE,
    component: <EstheticianList />,
  },
  {
    key: 'health-check',
    title: 'Health Check',
    path: HEALTH_CHECK_ROUTE,
    component: <HealthCheck />,
  },
  {
    key: 'appointment-confirmation',
    title: 'Appointment Confirmation',
    path: APPOINTMENT_CONFIRMATION_ROUTE,
    component: <AppointmentConfirmation />,
  },
  {
    key: 'confirm-appointment',
    title: 'Confirm Appointment',
    path: CONFIRM_APPOINTMENT_ROUTE,
    component: <ConfirmedAppointment />,
  },
]

export const LaserRouter = () => {
  useEffect(() => {
    document.title = 'Book A Laser Facial'
  }, [])
  return (
    <Routes>
      {laserRoutes.map((route) => {
        return (
          <Route
            path={route.path}
            element={route.component}
            key={route.key}
            caseSensitive={true}
          />
        )
      })}
      <Route key="404" path="*" element={<PageNotFound />} />
    </Routes>
  )
}
