import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import {
  CUSTOMER_SESSION_TOKEN,
  CUSTOMER_ACCESS_TOKEN,
  LOGIN_ROUTE,
  BOULEVARD_AUTH_API_TOKEN,
} from '../../utils/constants/Helpers'
import {
  getLocalstorageItem,
  removeLocalstorageItem,
} from '../../utils/helper-functions'
import { useNavigate, useSearchParams } from 'react-router'
import { AppData, JwtTokenPayload } from '../../utils/types/appTypes'
import { getUserInfo } from '../../services/User'
import { jwtDecode } from 'jwt-decode'

export interface IAppContextProp {
  appMasterData: AppData
  updateData: (_: Partial<AppData>) => void
  userLogout: () => void
}
const localToken = getLocalstorageItem(CUSTOMER_SESSION_TOKEN)
export const initialAppMasterData = {
  location: {
    name: '',
    locationId: '',
    tz: '',
    mapImg: '',
    advanceBookingLimit: 2,
    enableGroupBooking: false,
    enableGhostBooking: false,
    enableMembershipFlow: false,
    address: {
      city: '',
      country: '',
      streetAddress: '',
      state: '',
      zipCode: '',
    },
    contact: {
      phone: '',
      email: '',
      availability: [],
    },
    marketingSupport: {
      enableNewClientPromo: false,
    },
  },
  cart: {
    cartId: '',
    cartValue: 0,
    selectedCartItemId: '',
    isPromoCodeApplied: false,
    summary: {
      discountAmount: 0,
      gratuityAmount: 0,
      paymentMethodRequired: true,
      subtotal: 0,
      taxAmount: 0,
      total: 0,
      depositAmount: 0,
      deposit: '',
    },
    promoCodes: [],
    appointmentId: '',
    purchasedMembership: false,
  },
  enhancementOptionId: '',
  category: {
    categoryId: '',
  },
  email: '',
  selectedEnhancements: [],
  membershipVouchers: [],
  selectedDate: new Date(),
  selectedEsthetician: {
    id: '',
    duration: 0,
    price: 0,
    isAny: false,
    staff: {
      firstName: '',
      lastName: '',
      bio: '',
      id: '',
    },
  },
  selectedTime: {
    id: '',
    score: 0,
    startTime: '',
  },
  availableEnhancements: [],
  availableFacials: [],
  selectedFacial: '',
  availableEstheticians: [],
  membershipData: {
    id: '',
    name: '',
    description: '',
    listPrice: 0,
    __typename: '',
  },
  enhancement: false,
  isLoggedIn: false,
  isAgree: false,
  user: {
    token: localToken,
  },
  userTokenPayload: {
    id: '',
    customer_access_token: '',
    boulevard_id: '',
    shopify_id: '',
    iat: 0,
    exp: 0,
  },
  userInfo: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    is_member: false,
    membership_start_on: '',
    membership_agreement: false,
    boulevard_id: '',
  },
  filteredEstheticians: [],
  previousBookedAppointments: [],
  loginEmail: '',
  isLaserFacial: false,
  isUTMExist: false,
}

export const AppContext = createContext<IAppContextProp>({
  appMasterData: initialAppMasterData,
  updateData: () => {},
  userLogout: () => {},
})

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [appMasterData, setAppMasterData] =
    useState<AppData>(initialAppMasterData)

  const updateData = useCallback((appStateUpdate: Partial<AppData>) => {
    setAppMasterData((prev) => ({ ...prev, ...appStateUpdate }))
  }, [])
  const navigate = useNavigate()

  const userLogout = useCallback(() => {
    navigate(LOGIN_ROUTE)
    removeLocalstorageItem(CUSTOMER_ACCESS_TOKEN)
    removeLocalstorageItem(CUSTOMER_SESSION_TOKEN)
    removeLocalstorageItem(BOULEVARD_AUTH_API_TOKEN)
    updateData({ ...initialAppMasterData, user: { token: '' } })
  }, [navigate, updateData])

  const setLoggedinState = useCallback(async () => {
    const loginState = !!appMasterData.user.token
    if (loginState) {
      let response = await getUserInfo()

      const userTokenPayload = jwtDecode<JwtTokenPayload>(
        appMasterData.user.token!
      )
      updateData({
        isLoggedIn: loginState,
        userInfo: response,
        userTokenPayload,
      })
    } else {
      updateData({ isLoggedIn: loginState })
    }
  }, [appMasterData.user.token, updateData])

  let [searchParams] = useSearchParams()

  useEffect(() => {
    const utmSource = searchParams.get('utm_source')
    setAppMasterData((prev) => ({ ...prev, isUTMExist: !!utmSource }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLoggedinState().catch((e) => console.error(e))
  }, [setLoggedinState, appMasterData.user.token])

  const value = useMemo(() => {
    return { appMasterData, updateData, userLogout }
  }, [appMasterData, updateData, userLogout])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default AppProvider
