import { ReactNode } from 'react'
import { AvailableItems } from './cartTypes'
import { Esthetician, TimeSlot } from './estheticianTypes'
import {
  Address,
  AnalyticsLocationData,
  ContactInfo,
  MarketingSupport,
} from './locationTypes'
import { CartOffer } from './promoCodeTypes'
import { BookedAppointmentResponse, MembershipVoucher } from './userTypes'

export interface Location {
  locationId: string
  name: string
  tz: string
  mapImg: string
  openDate?: string
  advanceBookingLimit: number
  enableGroupBooking: boolean
  enableGhostBooking: boolean
  enableMembershipFlow: boolean
  address: Address
  contact: ContactInfo
  marketingSupport: MarketingSupport
}
export interface CartSummary {
  discountAmount: number
  gratuityAmount: number
  paymentMethodRequired: boolean
  subtotal: number
  taxAmount: number
  total: number
  depositAmount: number
  deposit: string
}

interface Cart {
  cartId: string
  cartValue: number
  summary: CartSummary
  isPromoCodeApplied: boolean
  promoCodes: CartOffer[]
  appointmentId: string
  purchasedMembership: boolean
}
interface Category {
  categoryId: string
  categoryObj?: AvailableItems
}

export interface JwtTokenPayload {
  id: string
  customer_access_token: string
  boulevard_id: string
  shopify_id: string
  iat: number
  exp: number
}
interface User {
  token: string | null
}

export interface Enhancement {
  id: string
  name: string
  description: string
  listPrice: number
  cartItemId?: string
  newLabel?: boolean
  order?: number
  newLabelText?: string
  icon?: ReactNode
  helpText?: string
  addon?: boolean
  limitedEdition?: boolean
  titleVisible?: string
}

export interface UserInfo {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  is_member: boolean
  boulevard_id: string
  membership_start_on: string
  membership_agreement: boolean
  membership_agreement_time?: Date
}

export interface Addon {
  id: string
  name: string
}

export interface Facial {
  id: string
  name: string
  listPrice: number
}

export interface AppData {
  location: Location
  cart: Cart
  enhancementOptionId: string
  category: Category
  email: string
  selectedEnhancements: Enhancement[]
  membershipVouchers: MembershipVoucher[]
  selectedDate: Date
  selectedEsthetician: Esthetician
  selectedTime: TimeSlot
  selectedFacial: string
  availableFacials: Facial[]
  availableEnhancements: Enhancement[]
  availableEstheticians: Esthetician[]
  membershipData: AvailableItems
  enhancement: boolean
  isLoggedIn: boolean
  isAgree: boolean
  user: User
  userTokenPayload: JwtTokenPayload
  userInfo: UserInfo
  filteredEstheticians: Esthetician[]
  previousBookedAppointments: BookedAppointmentResponse[]
  loginEmail: string
  isLaserFacial: boolean
  isUTMExist: boolean
  addons?: Addon[]
}

export interface FallBackData {
  location: AnalyticsLocationData
  is_promo_code_applied: boolean
  selected_esthetician: Esthetician
  enhancement: boolean
  is_loggedIn: boolean
  user_info: UserInfo | null
}

export enum HeaderTheme {
  LIGHT = 'LIGHT',
  NEUTRAL_TERTIARY = 'NEUTRAL_TERTIARY',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
}

export interface FacebookEvents {
  contents: string
  content_type: string
  content_name: string
  category: string
  date: string | Date
  value: number
  num_items: number
  quantity: string
  currency: string
}
