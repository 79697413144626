import {
  GRAPHQL_ENDPOINT,
  SIGN_MEMBERSHIP_AGREEMENT_API,
} from '../utils/constants/EndpointUrls'
import { AXIOS_METHODS } from '../utils/constants/Enums'
import { Axios } from './Axios'
import {
  AddBookableItemToCartResponse,
  AddSelectedItemToCartPayload,
} from '../utils/types/cartTypes'
import { getLocalstorageItem, tokenValidator } from '../utils/helper-functions'
import {
  BOULEVARD_PUBLIC_API_TOKEN,
  CUSTOMER_SESSION_TOKEN,
  LOCATION_STATE_CONFIG,
} from '../utils/constants/Helpers'
import { LocationPlace, LocationsRes } from '../utils/types/locationTypes'
import { chain } from 'lodash'
import { getLocations } from './Location'
import SanityClient from './sanityClient'

const getBoulevardClientHeader = () => {
  return {
    Authorization: `Basic ${getLocalstorageItem(BOULEVARD_PUBLIC_API_TOKEN)}`,
  }
}

/*
 * Mutations
 */

/*
 * Query
 */
const addSelectedToCartQuery = `query AddSelectedToCartQuery($cartId:String,$itemId:String){
  cart(id: $cartId) {
    id
    availableItem(id: $itemId) {
      id

      ... on CartAvailablePurchasableItem {
        name
        description
        listPriceRange {
          min
          max
          variable
        }
      }
    }
  }
}`
const addCartSelectedPurchasableItem = `mutation AddCartSelectedPurchasableItem($id:String,$itemId:String){
  addCartSelectedPurchasableItem(input: {
    id:$id,
    itemId:$itemId
  }) {
    cart {
      id
      summary{
  gratuityAmount
  paymentMethodRequired
  subtotal
  taxAmount
  total
  depositAmount
  deposit
      }
    }
  }
}`
/*
 * API calls
 */

export const addSelectedItemToCart = async (cartId: string, itemId: string) => {
  const payload: AddSelectedItemToCartPayload = {
    query: addSelectedToCartQuery,
    variables: {
      cartId,
      itemId,
    },
  }

  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    AddSelectedItemToCartPayload,
    AddBookableItemToCartResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const addSelectedPurchasableItemToCart = async (
  id: string,
  itemId: string
) => {
  const payload: AddSelectedItemToCartPayload = {
    query: addCartSelectedPurchasableItem,
    variables: {
      id,
      itemId,
    },
  }

  const response = await Axios.setHeaders(getBoulevardClientHeader()).callApi<
    AddSelectedItemToCartPayload,
    AddBookableItemToCartResponse
  >(AXIOS_METHODS.POST, GRAPHQL_ENDPOINT, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

const formatData = (locationData: LocationPlace[]): LocationsRes[] => {
  const result = chain(locationData)
    .groupBy('address.state')
    .map((value: any, key: string) => {
      return {
        locationName: LOCATION_STATE_CONFIG[key],
        locationId: value[0].id,
        places: value.filter((a: LocationPlace) => {
          return a.enableMembershipFlow
        }),
      }
    })
    .value()
  result.sort((a, b) => {
    const locationOne = a.locationName
    const locationTwo = b.locationName
    if (locationOne > locationTwo) {
      return 1
    }
    if (locationOne < locationTwo) {
      return -1
    }
    return 0
  })

  return result
}

export const getMembershipLocations = async () => {
  return await getLocations(formatData)
}

const agreementQuery = `*[_type == "legal" && title == "Membership Agreement" ]`

export const getMembershipAgreementPortableText = async () => {
  const response = await SanityClient.fetch(agreementQuery).catch(console.error)
  if (response) {
    return response[0].body
  } else {
    console.error(response)
    throw new Error(response)
  }
}

export const signMembershipAgreement = async (membershipAgreement: boolean) => {
  tokenValidator()
  const payload = {
    membershipAgreement,
  }
  const headers = {
    Authorization: `Bearer ${getLocalstorageItem(CUSTOMER_SESSION_TOKEN)}`,
  }
  const response = await Axios.setHeaders(headers).callApi<
    {
      membershipAgreement: boolean
    },
    any
  >(AXIOS_METHODS.PATCH, SIGN_MEMBERSHIP_AGREEMENT_API, undefined, payload)
  if (response.data) {
    return response.data
  } else {
    throw new Error(response.message)
  }
}
